import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {KnowledgeBaseRepository} from './kb-repository';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {sanityCient} from './sanity-client';

const imageUrlBuilder = require('@sanity/image-url');
const builder = imageUrlBuilder(sanityCient);

const blocksToHtml = require('@sanity/block-content-to-html');
const h = blocksToHtml.h;

const serializers = {
  types: {
    block: props => {
      if ('subsection' === props.node.style) {
        let id = null;
        if (props.node.children.length > 0) {
          id = props.node.children[0].text.toLowerCase().replaceAll(' ', '');
        }
        return h('h5', {className: props.node.style, id: id}, props.children);  // convert subsection to header tags
      }
      return h('div', {className: props.node.style}, props.children);
    },
    vimeo: props => {
      const vimeoId = props.node.url.split('/')[3];
      return h('div', {className: 'vimeo-frame'},
        h('iframe', {
          src: 'https://player.vimeo.com/video/' + vimeoId + '?h=5426d0b958&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          frameborder: '0',
          allow: 'autoplay; fullscreen; picture-in-picture',
          allowfullscreen: 'true',
          className: 'vimeo-player-frame',
          title: ''
        }));
    },
    image: props => {
      const url = builder.image(props.node.asset._ref).url();
      return h('img', {src: url, className: 'imageFrame'});
    }
  },
  marks: {
    icon: props => {
      return h('i', {className: 'material-icons-outlined inline-icon'}, props.children);
    },
    link: props => {
      if (props?.mark?.href?.startsWith('https://')) {
        return h(`a`, {target: `_blank`, href: props.mark.href}, props.children);
      } else if (props?.mark?.href != null) {
        return h(`a`, {href: props.mark.href}, props.children);
      } else {
        return h(`a`, {}, props.children);
      }
    }
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '';

  category: string;
  id: string;

  content: SafeHtml;

  constructor(private repository: KnowledgeBaseRepository, private sanitizer: DomSanitizer, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.url.subscribe(url => {
        if (url.length === 0 || url[0].path.length === 0) {
          return;
        }
        this.id = url[0].path;
        if (this.id == null) {
          return;
        }
        this.repository.entriesFor(this.id).then(r => {
          this.title = r.title;
          const content = blocksToHtml({
            blocks: r.content,
            serializers: serializers,
            imageOptions: {w: 320, h: 240, fit: 'max'},
            projectId: 'brotnxmf',
            dataset: 'snapshots',
          });
          this.content = this.sanitizer.bypassSecurityTrustHtml(content);
        });

      }
    );
  }
}


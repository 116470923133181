import {Injectable} from '@angular/core';
import {sanityCient} from './sanity-client';


@Injectable()
export class KnowledgeBaseRepository {

  constructor() {
  }

  /**
   * Returns Knowledge Base entries matching any of the given keys.
   */
  async entriesFor(id: string): Promise<any> {
    return sanityCient.fetch(`*[_type=="kb-entry" && _id=="${id}"] | order(_updatedAt desc)[0]`);
  }

}


<!--suppress JSUnresolvedLibraryURL -->
<link href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined" rel="stylesheet">
<script src="https://player.vimeo.com/api/player.js" type="application/javascript"></script>
<div style="padding-left:1rem; padding-right: 1rem">
  <h4>{{title}}</h4>
  <div style="padding-top: 16px"></div>
  <div [innerHTML]="content"></div>
  <router-outlet></router-outlet>
</div>

